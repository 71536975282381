<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Certifications
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiCertificateOutline }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-switch
          v-model="showArchived"
          label="Afficher les certifications archivées"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="$router.push({name:'certification-create'})"
        >
          Nouvelle Certification
        </v-btn>
      </v-card-text>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="table"
      ></v-skeleton-loader>
      <v-card-text v-else>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="certifications"
          class="elevation-1"
          @click:row="goToCert"
        >
          <!-- STATUS CHIP-->
          <template
            #[`item.status`]="{item}"
          >
            <item-status-chip
              :status="item.status"
            ></item-status-chip>
          </template>
          <!-- END STATUS CHIP-->
          <!-- PRICE -->
          <template
            #[`item.cost`]="{item}"
          >
            <div
              v-if="item.cost"
              class="d-flex flex-column text-center"
            >
              {{ item.cost.toLocaleString('fr-FR') }} €
            </div>
          </template>
          <!-- END PRICE -->
          <!-- LIEN EDITION -->
          <template #[`item.change`]="{item}">
            <div class="d-flex flex-column text-right">
              <router-link :to="{ name: 'certification-edit', params: { id: item.id }}">
                <v-icon>
                  {{ icons.mdiSquareEditOutline }}
                </v-icon>
              </router-link>
            </div>
          </template>
          <!-- END LIEN EDITION -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiLabel,
  mdiMagnify,
  mdiCertificateOutline,
} from '@mdi/js'
import ItemStatusChip from '@/components/ItemStatusChip.vue'

export default {
  metaInfo: {
    title: 'Certifications',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    ItemStatusChip,
  },
  data() {
    return {
      loading: true,
      showArchived: false,
      search: '',
      certifications: [],
      headers: [
        {
          text: 'Nom de la certification',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Organisme certificateur', value: 'certifier', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Coût', value: 'cost', align: 'center' },
        { text: 'Modification', value: 'change', align: 'end' },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiLabel,
        mdiMagnify,
        mdiCertificateOutline,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http.get(`${process.env.VUE_APP_API_URL}/certification/all/${this.$store.state.company._id}`)
        .then(res => {
          this.certifications = res.data
        })
        .catch(err => console.log(err))
        .finally(this.loading = false)
    },
    goToCert(e) {
      this.$router.push({ name: 'certification-edit', params: { id: e._id } })
    },
  },
}
</script>

<style scoped>
    th {
        text-align: center;
    }
</style>
