<template>
  <div>
    <v-chip
      :color="solveColor(status)"
    >
      {{ solveText(status) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'active',
    },
  },
  data() {
    return {
      statusFull: [
        {
          name: 'active',
          text: 'Active',
          color: 'success',
        },
        {
          name: 'archived',
          text: 'Archivé',
          color: 'secondary',
        },
        {
          name: null,
          text: 'Pas de status',
          color: 'warning',
        },
      ],
    }
  },
  methods: {
    solveColor(status) {
      return this.statusFull.find(x => x.name === status).color
    },
    solveText(status) {
      return this.statusFull.find(x => x.name === status).text
    },
  },
}
</script>

<style>

</style>
